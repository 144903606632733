export default function Zindex(els) {
  const zindex = document.querySelector(".video-wrapper");
  const embeddedVideo = document.querySelector(".embedded-video");

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      zindex.classList.toggle("z-[1000]");
      embeddedVideo.classList.toggle("iframe-image");
    })
  );
}
