import eventBus from "./utils/eventBus";

export default function Video(el) {
  if (typeof Plyr !== "function") return;

  const player = new Plyr(el, {});
  player.on("timeupdate", () => {
    if (player.currentTime >= player.duration - 1) {
      player.stop();
    }
  });

  eventBus.on("popup:close", () => {
    player.stop();
  });

  // Set the playback rate to 2x
  return () => {};
}
