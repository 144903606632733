import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Article(el) {
  const sections = [...el.querySelectorAll("[data-article]")];
  const nav = el.querySelector("[data-article-nav]");

  sections.forEach((section) => {
    if (section.dataset.title) {
      const link = document.createElement("a");
      link.innerText = section.dataset.title;
      link.href = "#/";
      nav.appendChild(link);

      const sc = ScrollTrigger.create({
        trigger: section,
        //   trigger viewport
        start: "top 50%",
        end: "top 0%",
        // markers: true,
        onEnter: () => {
          setActive(section);
        },
        onEnterBack: () => {
          setActive(section);
        },
      });
    }
  });

  document.addEventListener("lazyloaded", (e) => {
    console.log("lazyloaded");
    ScrollTrigger.refresh();
  });
  document.addEventListener("load", () => ScrollTrigger.refresh());

  function setActive(section) {
    const index = sections.indexOf(section);
    [...nav.children].forEach((c) => c.classList.remove("active"));
    nav.children[index].classList.add("active");
  }

  nav.querySelectorAll("a").forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const index = [...nav.children].indexOf(e.target);
      sections[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    })
  );
}
