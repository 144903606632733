import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

var tl = gsap.timeline();

export default function Animations() {
  // gsap.from(".intro-title", {
  //   duration: 1,
  //   opacity: 0,
  //   scrollTrigger: {
  //     trigger: ".intro-title",
  //     start: "top 90%",
  //     end: "top 70%",
  //   },
  // });
}
