import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import Form from "./js/Form";
import "./app.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Headroom from "headroom.js";
import Menu from "./js/Menu";
import Zindex from "./js/Zindex";
import Article from "./js/Article";
import Video from "./js/Video";
import Table from "./js/Table";
import Gallery from "./js/gallery";
import Signup from "./js/Signup";
import Animations from "./js/Animations";
import Slider from "./js/Slider";
import Slider2 from "./js/Slider2";
import eventBus from "./js/utils/eventBus";
import Alpine from "alpinejs";
import lazySizes from "lazysizes";
import Popup from "./js/Popup";
const simpleParallax = require("simple-parallax-js");

Alpine.start();

let actions = {};
let pageExit = {};

function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function addClass(el, className) {
  var el = document.querySelectorAll(el);

  for (i = 0; i < el.length; i++) {
    if (el.classList) {
      el[i].classList.add(className);
    } else {
      el[i].className += " " + className;
    }
  }
}

function runActions() {
  action("Slider", Slider);
  action("Slider2", Slider2);
  action("Form", Form);
  action("Gallery", Gallery);
  action("Video", Video);
  action("Article", Article);
  Animations();

  Signup(document.querySelectorAll(".signup-close"));
  Menu(document.querySelectorAll(".mob-nav-btn"));

  Popup(document.querySelectorAll(".popup-btn"));
  Zindex(document.querySelectorAll(".popup-btn"));
  document.querySelectorAll(".wysiwyg table").forEach(Table);

  // Toggling language popup on both desktop and in mobile navigation
  const languageButtons = document.querySelectorAll(".lang-btn");
  languageButtons.forEach((languageButton) => {
    languageButton.addEventListener("click", function (event) {
      const dropdownLanguages = languageButton.nextElementSibling;
      dropdownLanguages.classList.toggle("open");
    });
  });

  const headroomElement = document.querySelector("#main-header");
  if (window.location.pathname === "/") {
    const headroom = new Headroom(headroomElement, { offset: 248 });
    headroom.init();
  } else {
    const headroom = new Headroom(headroomElement, { offset: 250 });
    headroom.init();
  }
  if (document.querySelector(".main-carousel")) {
    var flkty = new Flickity(".main-carousel", {
      // options
      cellAlign: "left",
      contain: true,
      fade: true,
    });
  }
  var backToTopButton = document.getElementById("back-to-top-btn");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };
  var popupFormButton1 = document.querySelector("#popup-form-btn-1");
  var popupFormButton2 = document.querySelector("#popup-form-btn-2");
  var popupForm = document.querySelector(".popup-form");

  // Add event listener to button 1
  popupFormButton1.addEventListener("click", function () {
    // Toggle the 'hidden' class on the form
    popupForm.classList.toggle("hidden");
  });

  // Add event listener to button 2
  popupFormButton2.addEventListener("click", function () {
    // Toggle the 'hidden' class on the form
    popupForm.classList.toggle("hidden");
  });

  var closeButton = document.querySelector(".close-btn");

  // Add event listener to the close button
  closeButton.addEventListener("click", function () {
    // Hide the form
    popupForm.classList.add("hidden");
  });

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      backToTopButton.style.display = "block";
    } else {
      backToTopButton.style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  backToTopButton.addEventListener("click", function () {
    scrollToTop();
  });

  function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  var hoverables = document.querySelectorAll(".hoverable");
  hoverables.forEach(function (hoverable) {
    hoverable.addEventListener("mouseover", function () {
      var slideIndex = parseInt(this.getAttribute("data-slide-index")) - 1; // Adjust slide index (Flickity uses zero-based indexing)
      flkty.select(slideIndex); // Change slide when hovered over

      var divIndex = parseInt(this.classList[0].split("-")[1]);
      var div = document.querySelector(".div-" + divIndex);
      div.classList.add("active");
    });
    hoverable.addEventListener("mouseout", function () {
      // Remove "active" class from all divs
      document.querySelectorAll(".links-grid a").forEach(function (link) {
        link.classList.remove("active");
      });
    });
  });
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

(() => {
  let mounted = false;
  let service = {
    _open: false,
    overlay: document.querySelector(".overlay"),
    open() {
      this._open = true;

      gsap.to(this.overlay, 0.3, {
        opacity: 1,
      });
      document.body.classList.add("overflow-hidden");
    },
    close() {
      this._open = false;
      document.body.classList.remove("overflow-hidden");
      gsap.to(this.overlay, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.overlay.innerHTML = "";
        },
      });
    },
  };

  window.addEventListener("click", function (evt) {
    if (evt.target.classList.contains("close-modal")) {
      service.close();
    }
  });
})();

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

// document.getElementById("nocursor").style.cursor = "none";

document.querySelectorAll("[data-hover-target]").forEach((hoverEl) => {
  const target = document.querySelector(hoverEl.dataset.hoverTarget);
  hoverEl.addEventListener("mouseenter", () => {
    target.style.display = "block";
  });
  hoverEl.addEventListener("mouseleave", () => {
    target.style.display = "none";
  });
});

document.querySelectorAll("[data-unhover-target]").forEach((hoverEl) => {
  const target = document.querySelector(hoverEl.dataset.unhoverTarget);
  hoverEl.addEventListener("mouseenter", () => {
    target.style.display = "none";
  });
  hoverEl.addEventListener("mouseleave", () => {
    target.style.display = "block";
  });
});
var image = document.getElementsByClassName("paraImg");
new simpleParallax(image);

// Smooth scroll function
function smoothScroll(target, duration) {
  var targetElement = document.querySelector(target);
  var targetPosition = targetElement.getBoundingClientRect().top;
  var startPosition = window.pageYOffset;
  var startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    var timeElapsed = currentTime - startTime;
    var run = ease(timeElapsed, startPosition, targetPosition, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}
document.addEventListener("DOMContentLoaded", (event) => {
  // Select all anchor links
  const anchorLinks = document.querySelectorAll('a[href^="#"]');

  // Add click event listener to each anchor link
  anchorLinks.forEach((link) => {
    link.addEventListener("click", function (event) {
      event.preventDefault(); // Prevent default anchor click behavior

      // Get the target element
      const targetId = this.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        // Calculate the offset position
        const offsetPosition =
          targetElement.getBoundingClientRect().top + window.pageYOffset - 100;

        // Scroll to the offset position
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth", // Optional: for smooth scrolling
        });
      }
    });
  });
});
// Smooth scroll to section when anchor link is clicked
// var links = document.querySelectorAll('a:not([href="#"])');
// links.forEach(function (link) {
//   link.addEventListener("click", function (e) {
//     e.preventDefault();
//     var target = this.getAttribute("href");
//     var duration = 1000; // Adjust duration as needed
//     smoothScroll(target, duration);
//   });
// });

// Get references to the categories and images elements
const categories = document.querySelector(".categories");
const images = document.querySelector(".images");

// Add an event listener to the categories element
categories?.addEventListener("click", (event) => {
  // Check if the clicked element is a category button
  if (event.target.classList.contains("category")) {
    // Remove the "active" class from all category buttons
    document.querySelectorAll(".category").forEach((button) => {
      button.classList.remove("active");
    });

    // Add the "active" class to the clicked category button
    event.target.classList.add("active");

    // Get the category slug from the clicked button's data attribute
    const category = event.target.dataset.category;

    // Show or hide images based on the selected category
    document.querySelectorAll(".image").forEach((image) => {
      // image.style.display = "hidden";
      image.classList.add("hidden", "w-0");
    });

    document
      .querySelectorAll(`[data-categories='${category}']`)
      .forEach((image) => {
        image.classList.remove("hidden", "w-0");
      });

    eventBus.emit("slider-reinit");
  }
});

eventBus.on("ajax-load", () => {
  initMason();
  action("Gallery", Gallery);
  // runActions();
});

// Document readyState here is 'interactive' so will not raise DOMContentLoaded event
const buttons = document.querySelectorAll(".togglebtn");
const panels = document.querySelectorAll(".panel");

buttons.forEach((button) => {
  button.addEventListener("click", function () {
    // Remove 'active' class from all buttons
    buttons.forEach((btn) => btn.classList.remove("active"));
    // Add 'active' class to the clicked button
    this.classList.add("active");
    // Get the corresponding panel ID
    const panelId = this.id.replace("button-", "panel-");
    // Hide all panels
    panels.forEach((panel) => panel.classList.add("hidden"));
    // Show the panel corresponding to the clicked button
    document.getElementById(panelId).classList.remove("hidden");
  });
});
